export default [

    {
        id: 1,
        title: " Regression Analytics Project", 
        description: "Would implementing Universal healthcare in the US Increase life expectancy at birth?",
        image: "Regression.png",
    }
    ,
    {
        id: 2,
        title: "Buxton Challange Project", 
        description: "Exploring retail site selection process for Slacks on Racks company using predecitive analytics.",
        image:"Buxton.png",
    }
    ,
    {
        id: 3,
        title: " Business & Econ. Forecasting", 
        description: "Producing time series forecast on investments (DJIA index) using various time series analysis with respect to macroeconomic variables.",
        image: "BusinessEcon.jpeg",
    },
    {
        id: 4,
        title: "Data Mining Project", 
        description: "Predicted the average weekley sales after implementing suggested markdown program with various factors affecting the stroes across the USA.",
        image: "DataMining.jpg",
    },
]