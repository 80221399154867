import "../css/introduction.css";
import waving_hand from '../images/waving_hand.png';
import my_image from '../images/background.png'
import my_resume from '../files/my_resume.pdf'

export default function Introduction() {
    return (
        <div className="Intro-items">        
            <div className="App">
            <header className="App-header">
                <a className="Introduction">
                    Hey there!
                </a>
                <img src={waving_hand} className="waving_hand" alt="logo" />
            </header>
            <a className="Name">
                I'm Saurav, an economist in Dallas
            </a>
            <a className="AboutMe" href={my_resume} target="_blank">
                check my resume &#8594;
            </a>
            </div>
            <img src={my_image} className="my_image" alt="logo" />    
        </div>

    )

}
