import "../css/projectCard.css"
import BusinessEcon from "../files/Business&EconomicForecasting.pdf"
import Buxton from "../files/Buxton Challange Project.pdf"
import DataMining from "../files/DataMiningProject.pdf"
import Regression from "../files/RegressionAnalyticsCapstoneProject.pdf"

export default function ProjectCard(props){
    return(
        <div className="ProjectCard">
            <h3 className="CardName">{props.title}</h3>
            <img className="CarImage" src={props.image}/>
            <p className="CardDescription">{props.description}</p>

            
            {props.id == 1?(<a className="CardGithub" href={Regression} target="_blank">view pdf</a>):( <a></a>)}
            {props.id == 2?(<a className="CardGithub" href={Buxton} target="_blank">view pdf</a>):( <a></a>)}
            {props.id == 3?(<a className="CardGithub" href={BusinessEcon} target="_blank">view pdf</a>):( <a></a>)}
            {props.id == 4?(<a className="CardGithub" href={DataMining} target="_blank">view pdf</a>):( <a></a>)}


            </div>
    )

}