import '../css/connect.css';
import Instagram from '../images/instagram.png'
import Linkedin from '../images/linkedin.png'
import Github from '../images/github.png'
import my_resume from '../files/my_resume.pdf'

export default function Connect(){
    return (
        <div className="Connect">
        <p>© 2022 Saurav Bhusal</p>
        <div className="Connect-items">
            <a href="//instagram.com/saurabhusal" target="_blank"> <img src = {Instagram}/></a>
            <a href="//linkedin.com/in/sauravbhusal/" target="_blank"> <img src = {Linkedin}/></a>
        </div>
        </div>
    )
}