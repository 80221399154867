import "../css/about.css"
import MyImage from "../images/my-image.JPG"
import AboutIcon from "../images/about-icon.png"

export default function About(){
    return(
        <div className="about-items">
            <img  id="about" className="myImage" src = {MyImage}/>
            <div className="information">
                <div className="More-About">
                    <h2>More about me</h2>
                    <img src={AboutIcon} className="About-Icon"/>
                </div>
                
                <p>Hello! My name is Saurav Bhusal. I will graduate from the University of Texas at 
                    Arlington with my graduate degree in economic data analytics. 
                    I am a passionate learner, dedicated to utilizing data to analyze the incentives 
                    and constraints that shape our world. I am seeking a full-time Economic Data Analyst 
                    position where I can combine my passion for economics with my quantitative 
                    skills in data extraction, scraping, developing multivariate regressions, 
                    and trend analysis.</p>
            </div>
        </div>
    )
}