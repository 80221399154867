import "../css/experience.css"

export default function Experience(){
    return(
        <div id = "experience" className="experience-items">
            <div className="information">
                <div className="Experience">
                    <h2>Experience</h2>
                </div>
                
                <p>
                <h3>Smart-iPlace</h3> 
                <h4> February 2023 – Present</h4>
                • Conducted ad hoc analysis using SQL queries and visualizations to answer business questions, identify trends, and provide insights to stakeholders <br/>
                • Performed data extraction, transformation, and loading(ETL) processes using SSIS and Python scripts <br/>
                • Created interactive dashboards and reports using PowerBI and SSRS to visualize and analyze sales, inventory, and customerdata
      

          
                <h3>University of Texas at Arlington Graduate Teaching Assistant</h3> 
                <h4> January 2022 – December 2022</h4>
                • Evaluated exams and assignments for undergraduate courses in micro and macroeconomics <br/>
                • Instructed tutoring office hours outside of class to assist students’ learning <br/>
                </p>
            </div>
        </div>
    )
}