import "../css/experience.css"

export default function Education(){
    return(
        <div id = "education" className="experience-items">
            <div className="information">
                <div className="More-About">
                    <h2>Education</h2>
                </div>
                
                <p>
                <h3>University of Texas at Arlington, Arlington, TX </h3>
Masters in Economic Data Analytics 
<br/>Fall 2022
<br/>Graduate program focused on applying economic logic for data analysis
<br/>Relevant Courses : Advanced Econometrics, Monetary Policy and Financial System analysis, Economic Forecasting, Advanced Business Communication
  
<br/>
<br/>  

<h3>University of Texas at Arlington, Arlington, TX </h3>    
Bachelor of Science in Economics, Minor in Busines Administration
<br/>Fall 2021
<br/>Undergraduate program focused on Economic analytics in Business Settings
<br/>Relevant Courses : Advanced Statistics, Macro & Micro Economics, Economic Modelling, Economic Capstone Project

                </p>
            </div>
        </div>
    )
}