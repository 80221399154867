import Navbar from './js/Navbar';
import Introduction from './js/Introduction';
import Connect from './js/Connect';
import Skill from './js/Skill';
import About from './js/About';
import Projects from './js/Projects';
import Education from './js/Education';
import Experience from './js/Experience';
import './App.css';


function App() {
  return (
    <>
    <Navbar />
    <Introduction />
    <Skill />
    <Education />
    <Experience />
    <Projects />
    <About />
    <Connect />

    </>
  );
}

export default App;
